<template>
  <div class="ParamsCharts">
    <el-row :gutter="10">
      <el-col :span="2" class="btns">
        <el-button class="iconfont icon-dcdaochu1" @click="exportToImg">导出</el-button>
      </el-col>
      <el-col :offset="4" :span="3">
        <el-cascader v-model="lineValue" placeholder="工厂结构" :props="Props" :options="lineTree" @change="LinehandleChange"></el-cascader>
      </el-col>
      <el-col :span="3">
        <el-select @change="DeivcehandleChange" v-model="deviceId" placeholder="选择设备">
          <el-option v-for="item in DeviceOptions" :key="item.deviceId" :label="item.name" :value="item.deviceId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-select collapse-tags v-model="paramsId" placeholder="选择参数" multiple>
          <el-option v-for="item in paramsOptions" :key="item.paramId" :label="item.paramName" :value="item.paramId"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="3">
        <el-date-picker :disabled="isComparison" v-model="dateDay" value-format="yyyy-MM-dd" type="date" :editable="false" placeholder="选择日期"> </el-date-picker>
      </el-col>
      <el-col :span="4">
        <el-time-picker
          is-range
          :disabled="isComparison"
          v-model="dateTime"
          :picker-options="timeChange"
          value-format="HH:mm:ss"
          range-separator="-"
          :editable="false"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          placeholder="选择时间范围"
        >
        </el-time-picker>
      </el-col>
      <el-col :span="2" class="btns">
        <el-button class="joinAnalyze" icon="el-icon-plus" v-if="isComparison" @click="Search">加入分析</el-button>
        <el-button icon="el-icon-search" v-else @click="Search">查询</el-button>
      </el-col>
    </el-row>
    <div class="paramsName" v-if="deviceTagList.length">设备</div>
    <div class="deviceList" v-if="deviceTagList.length">
      <div v-for="item in deviceTagList" :key="item.deviceId" @click="closeDevice(item)">
        <div class="devicetag" v-if="item.deviceName">{{ item.deviceName }}</div>
      </div>
      <!-- <span v-for="item in deviceTagList" :key="item.deviceId">
        <el-tag type="info" style="margin-right: 10px" v-if="item.deviceName" closable @close="closeDevice(item)">{{ item.deviceName }}</el-tag>
      </span> -->
    </div>
    <baseSection name="参数报表">
      <div class="charts" v-if="chartDataList">
        <div v-for="(item, index) in chartDataList" :key="item.deviceId + item.tag || index">
          <Charts v-if="item !== 0" :chartData="item" />
        </div>
      </div>
    </baseSection>
  </div>
</template>

<script>
import baseSection from '@/components/section/base-section';
import Charts from './charts.vue';
import domtoimage from 'dom-to-image';
// import Timerpick from '@/components/TimerPick/index.vue';
export default {
  name: 'ParamsCharts',
  components: {
    Charts,
    baseSection
    // Timerpick
  },
  data() {
    return {
      lineTree: [],
      lineValue: [],
      Props: {
        label: 'name',
        value: 'id',
        expandTrigger: 'hover'
      },
      DeviceOptions: [],
      deviceId: '',
      paramsOptions: [],
      paramsId: '',
      dateDay: `${this.frontOneHour('yyyy-MM-dd', true)}`,
      dateTime: [this.frontOneHour('hh:mm:ss', false), this.frontOneHour('hh:mm:ss', true)],
      type: 'date',
      isComparison: false,
      chartDataList: [],
      deviceTagList: [],
      paramsTagList: [],
      timeChange: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 3600 * 1000 * 24 * 183 || time.getTime() > Date.now();
        }
      }
    };
  },
  created() {
    this.init();
  },
  methods: {
    exportToImg() {
      if (!this.chartDataList.length) {
        this.$message.warning('无数据，无法导出');
        return;
      }
      domtoimage
        .toPng(document.querySelector('.charts'))
        .then(function (dataUrl) {
          let img = new Image();
          img.src = dataUrl;
          let a = document.createElement('a');
          a.setAttribute('href', dataUrl);
          a.setAttribute('download', '参数统计.png');
          a.click();
        })
        .catch(function () {
          this.$message.error('无数据无法导出');
        });
    },
    async init() {
      const treeRes = (await this.$apis.FactoryChart.list()).map(item => {
        return {
          ...item,
          label: item.name
        };
      });
      this.lineTree = treeRes;
    },
    async LinehandleChange() {
      if (this.lineValue.length !== 3) {
        return false;
      }
      this.DeviceOptions = [];
      this.deviceId = '';
      const data = {
        path: this.lineValue[this.lineValue.length - 1],
        currentPageSize: 500
      };
      const deviceRes = await this.$apis.LSDconfig.getdevice(data);
      this.DeviceOptions = deviceRes;
    },
    async DeivcehandleChange() {
      this.paramsOptions = [];
      this.paramsId = '';
      const paramsRes = await this.$apis.paramsCharts.getparams({ deviceId: this.deviceId });
      this.paramsOptions = paramsRes;
    },
    frontOneHour(fmt, isone) {
      let currentTime;
      if (isone) {
        currentTime = new Date(new Date().getTime());
      } else {
        currentTime = new Date(new Date().getTime() - 1 * 60 * 60 * 1000);
      }
      //@currentTime  Wed Dec 29 2021 16:12:12 GMT+0800 (中国标准时间)?
      let o = {
        'M+': currentTime.getMonth() + 1, // 月份
        'd+': currentTime.getDate(), // 日
        'h+': currentTime.getHours(), // 小时
        'm+': currentTime.getMinutes(), // 分
        's+': currentTime.getSeconds(), // 秒
        'q+': Math.floor((currentTime.getMonth() + 3) / 3), // 季度
        S: currentTime.getMilliseconds() // 毫秒
      };
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, `${currentTime.getFullYear()}`.substr(4 - RegExp.$1.length));
      }
      for (let k in o) {
        if (new RegExp(`(${k})`).test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : `00${o[k]}`.substr(`${o[k]}`.length));
        }
      }
      return fmt;
    },
    async Search() {
      this.ishistogram = true;
      this.isline = false;
      this.iscurve = false;
      let params = [];
      this.paramsOptions.forEach(item => {
        this.paramsId.forEach(ele => {
          if (item.paramId === ele) {
            params.push(item);
          }
        });
      });
      let paramNames = [];
      let tags = [];
      let deviceName = '';
      this.DeviceOptions.forEach(item => {
        if (item.deviceId === this.deviceId) {
          deviceName = item.name;
        }
      });
      params.forEach(item => {
        paramNames.push(item.paramName);
        tags.push(item.tag);
      });
      const data = {
        deviceId: this.deviceId,
        paramNames: paramNames.join(','),
        deviceName,
        tags: tags.join(','),
        startDate: `${this.dateDay} ${this.dateTime[0]}`,
        endDate: `${this.dateDay} ${this.dateTime[1]}`
      };
      const chartsRes = await this.$apis.paramsCharts.getdata(data);
      if (!chartsRes) {
        return false;
      }
      if (this.isComparison) {
        let newRes = JSON.parse(JSON.stringify(chartsRes));
        this.paramsTagList.forEach(item => {
          chartsRes.forEach((ele, i) => {
            let tag = `${ele.deviceId}_${ele.tag}`;
            if (item.DeviceTag === tag) {
              newRes.splice(i, 1, '');
            }
          });
        });
        chartsRes.forEach(item => {
          this.paramsTagList.push({ DeviceTag: `${item.deviceId}_${item.tag}` });
        });
        let newParams = [];
        newRes.forEach(item => {
          if (item) {
            newParams.push(item);
          }
        });
        this.chartDataList.push(...newParams);
      } else {
        this.chartDataList = chartsRes;
        chartsRes.forEach(item => {
          this.paramsTagList.push({ DeviceTag: `${item.deviceId}_${item.tag}` });
        });
        this.isComparison = true;
      }
      this.chartDataList.forEach(item => {
        this.deviceTagList.push({ deviceName: item.deviceName, deviceId: item.deviceId });
      });
      this.unique(this.deviceTagList);
    },
    closeDevice(data) {
      this.deviceTagList.forEach((item, index) => {
        if (item.deviceId === data.deviceId) {
          this.deviceTagList.splice(index, 1);
        }
      });
      this.paramsTagList.forEach((item, index) => {
        if (item.DeviceTag) {
          if (item.DeviceTag.search(`${data.deviceId}`) > -1) {
            this.paramsTagList.splice(index, 1, '');
          }
        }
      });
      if (this.deviceTagList.length === 0) {
        this.chartDataList = [];
        this.paramsTagList = [];
        this.isComparison = false;
        return false;
      }
      this.chartDataList.forEach((item, index) => {
        if (item.deviceId === data.deviceId) {
          this.chartDataList.splice(index, 1, 0);
        }
      });
    },
    // 复杂数组去重
    unique(arr) {
      for (let i = 0; i < arr.length; i++) {
        for (let j = i + 1; j < arr.length; j++) {
          if (arr[i].deviceId === arr[j].deviceId) {
            //第一个等同于第二个，splice方法删除第二个
            arr.splice(j, 1);
            j--;
          }
        }
      }
      return arr;
    }
  }
};
</script>

<style scoped lang="scss">
/deep/ .el-form-item > .el-form-item__label {
  font-size: 16px;
}
/deep/.joinAnalyze {
  width: 107px !important;
}
.iconfont {
  line-height: 11px;
}
.el-row {
  margin-bottom: 20px;
}
.paramsName {
  font-size: 18px;
  margin-bottom: 5px;
}
.deviceList {
  display: flex;
  flex-wrap: wrap;
  width: 630px;
  padding: 10px 20px;
  border-radius: 4px;
  margin-bottom: 20px;
  border: 1px solid #dcdcdc;
}
.charts {
  width: 100%;
}

.el-cascader,
.el-select,
.el-date-editor,
.el-range-editor {
  width: 100%;
}
.btns {
  display: flex;
  .el-button {
    height: 36px;
  }
}
.devicetag {
  position: relative;
  margin-right: 14px;
  padding: 10px 8px;
  margin-bottom: 10px;
  background-color: #f3f3f3;
  border-radius: 4px;
  color: #909399;
  cursor: pointer;
}
.devicetag:hover {
  background-color: rgba(243, 152, 0, 0.1);
  color: #f39800;
  cursor: pointer;
}
.devicetag:hover::after {
  content: 'x';
  position: absolute;
  width: 12px;
  height: 12px;
  font-size: 10px;
  line-height: 10px;
  border-radius: 50%;
  text-align: center;
  background-color: #f39800;
  color: #fff;
  top: -4px;
  right: -4px;
}
.el-button {
  width: 90px;
  height: 36px;
}
</style>
