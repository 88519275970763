<template>
  <div class="ParamsChartsItem">
    <div class="paramsName">{{ chartData.paramName }}</div>
    <div ref="charts"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts';
export default {
  data() {
    return {
      Xdata: [],
      Ydata: [],
      tag: ''
    };
  },
  props: {
    chartData: {
      type: Object,
      require: true
    }
  },
  watch: {
    Xdata() {
      this.init();
    }
  },
  mounted() {
    this.Xdata = this.chartData.xAxisValues;
    this.Ydata = this.chartData.yAxisValues;
    if (!this.Ydata.length) {
      return false;
    }
    for (let i = 0; i < this.Ydata.length; i++) {
      if (this.Ydata[i] === 'false') {
        this.Ydata[i] = 0;
      } else if (this.Ydata[i] === 'true') {
        this.Ydata[i] = 1;
      }
    }
    this.tag = this.chartData.tag;
    this.init();
  },
  methods: {
    init() {
      this.$refs.charts.style.height = 'calc(100% - 30px)';
      this.myChart = echarts.init(this.$refs.charts);
      let option = {
        grid: {
          left: '0%',
          right: '1%',
          bottom: '0%',
          top: '10%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.Xdata
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'value'
        },
        dataZoom: [
          {
            type: 'inside'
          }
        ],
        series: [
          {
            data: this.Ydata,
            type: 'line',
            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgba(243, 152, 0, 0.4)'
                },
                {
                  offset: 1,
                  color: 'rgb(252, 255, 251)'
                }
              ])
            },
            itemStyle: {
              color: 'rgba(243, 152, 0, 1)',
              lineStyle: {
                color: 'rgba(243, 152, 0, 0.4)'
              }
            },
            smooth: true
          }
        ]
      };

      option && this.myChart.setOption(option);
    }
  }
};
</script>
<style scoped>
.ParamsChartsItem {
  height: 250px;
  width: 100%;
}
.paramsName {
  font-size: 16px;
}
</style>
